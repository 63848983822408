
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'babel-polyfill';
import moment from 'moment';
import arLocale from 'moment/locale/ar';
import withRedux from 'next-redux-wrapper';
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { Fragment, useEffect, useState } from 'react';
import { connect, Provider } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import rtlPlugin from 'stylis-rtlcss';
import { theme } from '../components/Constants';
import Head from '../components/Head';
import MainLoader from '@/components/MainLoader';
import grid from '../static/assets/grid.ts';
import '../static/style/media.scss';
import '../static/style/styles.scss';
import '../components/DatePicker.css';
import '../components/MonthPicker/MonthPicker.css';
import { initializeStore } from '../store';
import { loadProfile } from '../store/actions/profile';
import { initGA, logEvent } from '../utils/analytics';
import { getUrl } from '../utils/api';
import useTranslation from 'next-translate/useTranslation';
import Cookies from 'js-cookie';
import ErrorBoundary from '../components/ErrorBoundary';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
const useFontLoader = () => {
    const [fontLoaded, setFontLoaded] = useState(false);
    useEffect(() => {
        document.fonts.ready.then(() => setFontLoaded(true));
    }, []);
    return fontLoaded;
};
const useLocalStorageSetup = currentAPI_Reach => {
    useEffect(() => {
        if (!window.localStorage.getItem('login_time')) {
            window.localStorage.setItem('login_time', moment(new Date()));
        }
        const reachAccounts = window.localStorage.getItem('reachAccounts');
        if (!reachAccounts) {
            getUrl(`${currentAPI_Reach}/profile`, 'reach_token')
                .then(profile => {
                const access_token = Cookies.get('token');
                const reach_access_token = Cookies.get('reach_token');
                const accountList = [
                    {
                        token: access_token,
                        reach_token: reach_access_token,
                        profile: profile?.data,
                        isActive: true,
                    },
                ];
                window.localStorage.setItem('reachAccounts', JSON.stringify(accountList));
            })
                .catch(err => console.log(err));
        }
    }, [currentAPI_Reach]);
};
const useGASetup = () => {
    useEffect(() => {
        if (!window.GA_INITIALIZED) {
            initGA();
            window.GA_INITIALIZED = true;
        }
        logEvent('ReachPlusAnalyticsIntegrityTest', 'ReachPlusAnalyticsIntegrityTest');
        window.addEventListener('beforeunload', () => {
            const diffInDays = moment().diff(window.localStorage.getItem('login_time'), 'days') + 'd';
            const diffInMin = moment().diff(window.localStorage.getItem('login_time'), 'minutes') +
                'm';
            window.localStorage.setItem('logout', Date.now());
            logEvent('session', `${window.localStorage.getItem('username')}-duration(${diffInDays}h:${diffInMin}m)`);
            window.localStorage.removeItem('login_time');
        });
    }, []);
};
const MyApp = ({ Component, pageProps, store, profileData, dispatch }) => {
    const [mounted, setMounted] = useState(false);
    const fontLoaded = useFontLoader();
    const currentAPI = process.env.API_URL;
    const currentAPI_Reach = process.env.REACHNETWORK_API_URL;
    const currentReachURL = process.env.REACHNETWORK_URL;
    const currentCookie = Cookies.get('token');
    const { lang } = useTranslation('common');
    useEffect(() => {
        setMounted(true);
        window.addEventListener('beforeunload', componentGracefulUnmount);
        return () => {
            componentGracefulUnmount();
        };
    }, []);
    useLocalStorageSetup(currentAPI_Reach);
    useGASetup();
    useEffect(() => {
        if (currentCookie !== Cookies.get('token')) {
            dispatch(loadProfile(currentAPI_Reach));
        }
    }, [currentCookie, currentAPI_Reach, dispatch]);
    const componentGracefulUnmount = () => {
        setMounted(false);
        window.removeEventListener('beforeunload', componentGracefulUnmount);
    };
    if (lang === 'ar') {
        moment.locale('ar', arLocale);
    }
    else {
        moment.locale('en');
    }
    return (<Fragment>
      <Head />
      {mounted && fontLoaded ? (<Provider store={store}>
          <ThemeProvider theme={theme}>
            <div className={`full-content-wrapper ${lang === 'ar' ? 'rtl ' : 'ltr '}`}>
              <GridThemeProvider gridTheme={grid}>
                <StyleSheetManager stylisPlugins={lang === 'ar' ? [rtlPlugin] : []}>
                  <>
                    <ToastContainer closeOnClick theme="light"/>
                    <ErrorBoundary>
                      <Component isArabic={lang === 'ar'} currentAPI={currentAPI} currentAPI_Reach={currentAPI_Reach} currentReachURL={currentReachURL} profileData={profileData} dispatch={dispatch} {...pageProps}/>
                    </ErrorBoundary>
                  </>
                </StyleSheetManager>
              </GridThemeProvider>
            </div>
          </ThemeProvider>
        </Provider>) : (<MainLoader />)}
    </Fragment>);
};
const mapStateToProps = state => ({
    profileData: state.profile.profileList,
});
MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    const { Component, ctx } = appContext;
    let pageProps = {};
    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
    }
    pageProps.query = ctx.query;
    return { ...appProps, pageProps };
};
const __Next_Translate__Page__19381f69708__ = withRedux(initializeStore)(connect(mapStateToProps)(MyApp));

    export default __appWithI18n(__Next_Translate__Page__19381f69708__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  