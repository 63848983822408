import axios from 'axios';
import { logout } from './auth.js';
import {
  clearSession,
  handleCookie,
  requestHeaders,
  validateResponse,
} from './index.ts';
import { customConfigs } from './constant.ts';

const userToken = handleCookie('token');

export const postUrl = async (url = '', data = {}, token) => {
  const response = await fetch(url, {
    method: 'POST',
    ...customConfigs(userToken || token),
    body: data,
  });
  validateResponse(response);
  return response;
};

export const getUrl = async (url = '', tokenType = 'token') => {
  // Default options are marked with *
  const token = handleCookie(tokenType);
  if (!token) return;
  const response = await fetch(url, {
    headers: {
      Authorization: 'Bearer ' + token,
      ...requestHeaders(),
    },
  });
  if (response.ok) {
    const json = response.json();
    if (response.status === 500 || response.status === 401) {
      logout();
      clearSession();
      window.location.href = '/login';
    }
    return json;
  } else {
    clearSession();
    window.location.href = '/login';
  }
};

export const delUrl = async (url = '', tokenType = 'token') => {
  await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + handleCookie(tokenType),
    },
  });
};
// ! Used in RecentCampagins.js [Difference is return] cuz I need to handle Errors/Success States
export const delUrlCustom = (url = '', tokenType = 'token') => {
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + handleCookie(tokenType),
    },
  });
};

export const putUrl = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'PUT',
    ...customConfigs(userToken),
    body: data, // body data type must match "Content-Type" header
  });
  validateResponse(response);
  return response;
};

export const customPost = async (url = '', data = {}) =>
  axios(url, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + handleCookie('reach_token'),
      ...requestHeaders(),
    },
    data,
  });

export const influencerPost = async (url = '', data = {}, contentTypeJson) => {
  const ifContentTypeJson = contentTypeJson
    ? {
        'Content-Type': 'application/json',
      }
    : {};
  return axios(url, {
    method: 'POST',
    headers: {
      ...ifContentTypeJson,
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + handleCookie('token'),
      'Content-Language': handleCookie('next-i18next'),
    },
    data: data,
  });
};

export const postRequest = async (url = '', data = {}) =>
  axios(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + handleCookie('token'),
      'Content-Language': handleCookie('next-i18next'),
    },
    data,
  });
